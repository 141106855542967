import * as React from "react"
import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import slugify from "slugify"
import ContactForm from "../components/ContactForm"

const ContactSection = () => {
  const { t } = useTranslation()
  const heading = t("contact.heading")
  const slug = slugify(heading, { lower: true })
  return (
    <Box as="section" bg="white" paddingY={20} id={slug}>
      <Container maxW="container.xl" textAlign="center">
        <Heading size="3xl" marginBottom={2}>
          {heading}
        </Heading>
        <Heading size="md" variant="thin">
          <Trans>contact.subheading</Trans>
        </Heading>

        <ContactForm
          render={({ submitting }) => (
            <Stack spacing={8} alignItems="center" marginY={12}>
              <FormControl isRequired maxWidth="40ch">
                <FormLabel htmlFor="name" textAlign="center">
                  <Trans>contact.name</Trans>
                </FormLabel>
                <Input name="name" id="name" type="text" />
              </FormControl>
              <FormControl isRequired maxWidth="40ch">
                <FormLabel htmlFor="email" textAlign="center">
                  <Trans>contact.email</Trans>
                </FormLabel>
                <Input name="email" id="email" type="email" />
              </FormControl>
              <FormControl isRequired maxWidth="40ch">
                <FormLabel htmlFor="message" textAlign="center">
                  <Trans>contact.message</Trans>
                </FormLabel>
                <Textarea name="message" id="message" />
              </FormControl>
              <Button type="submit" isLoading={submitting}>
                <Trans>contact.button</Trans>
              </Button>
            </Stack>
          )}
        />

        <Box marginTop={8}>
          <Heading size="xl" marginBottom={2}>
            <Trans>360 Digital Natives Sp. z o.o.</Trans>
          </Heading>
          <Heading size="md" variant="thin">
            <Trans>contact.adress.1</Trans>
            <br />
            <Trans>contact.adress.2</Trans>
            <br />
            <Trans>contact.adress.3</Trans>
          </Heading>
        </Box>
      </Container>
    </Box>
  )
}

export default ContactSection
