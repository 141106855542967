import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../sections/Hero"
import AboutUs from "../sections/AboutUs"
import Outsource from "../sections/Outsource"
import Contact from "../sections/Contact"

const IndexPage = () => (
  <Layout>
    <Seo />
    <Hero />
    <AboutUs />
    <Outsource />
    <Contact />
  </Layout>
)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
