module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pl","en"],"defaultLanguage":"pl","siteUrl":"https://360digitalnatives.pl/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/cookies-policy","languages":["en"],"excludeLanguages":["pl"]},{"matchPath":"/:lang?/privacy-policy","languages":["en"],"excludeLanguages":["pl"]},{"matchPath":"/:lang?/terms-and-conditions","languages":["en"],"excludeLanguages":["pl"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4012a0ce554b025f26ed704f5685d996"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
