import * as React from "react"
import { graphql } from "gatsby"
import {
  Box,
  Flex,
  Button,
  Container,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Grid,
} from "@chakra-ui/react"
import { Trans } from "gatsby-plugin-react-i18next"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ContactForm from "../components/ContactForm"

const IndexPage = () => (
  <Layout>
    <Seo title="" />
    <Container maxW="container.lg" marginY={24}>
      <Box textAlign="center">
        <Heading size="3xl" marginBottom={2}>
          <Trans>outsource.heading</Trans>
        </Heading>
        <Heading size="md" variant="thin" marginBottom={6}>
          <Trans>outsource.subheading</Trans>
        </Heading>
      </Box>
      <ContactForm
        render={({ submitting }) => (
          <>
            <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }} gap={8}>
              <FormControl isRequired>
                <FormLabel htmlFor="name">
                  <Trans>contact.name</Trans>
                </FormLabel>
                <Input name="name" type="text" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="email">
                  <Trans>contact.email</Trans>
                </FormLabel>
                <Input name="email" type="email" />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="phone">
                  <Trans>contact.phone</Trans>
                </FormLabel>
                <Input name="phone" type="text" />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="company">
                  <Trans>contact.company</Trans>
                </FormLabel>
                <Input name="company" type="text" />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="industry">
                  <Trans>contact.industry</Trans>
                </FormLabel>
                <Input name="industry" type="text" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="description">
                  <Trans>contact.description</Trans>
                </FormLabel>
                <Input name="description" type="text" />
              </FormControl>
            </Grid>
            <FormControl isRequired marginTop={8}>
              <Checkbox>
                <Box fontSize="sm">
                  <Trans>contact.legal</Trans>
                </Box>
              </Checkbox>
            </FormControl>
            <Flex justifyContent="center">
              <Button type="submit" isLoading={submitting} marginTop={8}>
                <Trans>contact.button</Trans>
              </Button>
            </Flex>
          </>
        )}
      />
    </Container>
  </Layout>
)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
