import * as React from "react"
import { Box, Flex, Container, Heading, Image } from "@chakra-ui/react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import slugify from "slugify"

import img1 from "../images/aboutus/1.svg"
import img2 from "../images/aboutus/2.svg"
import img3 from "../images/aboutus/3.svg"
import img4 from "../images/aboutus/4.svg"
import img5 from "../images/aboutus/5.svg"

const CARDS = [
  { heading: "about.1.heading", subheading: "about.1.subheading", image: img1 },
  { heading: "about.2.heading", subheading: "about.2.subheading", image: img2 },
  { heading: "about.3.heading", subheading: "about.3.subheading", image: img3 },
  { heading: "about.4.heading", subheading: "about.4.subheading", image: img4 },
  { heading: "about.5.heading", subheading: "about.5.subheading", image: img5 },
]

const SectionAboutUs = () => {
  const { t } = useTranslation()
  const heading = t("about.heading")
  const slug = slugify(heading, { lower: true })
  return (
    <Box as="section" bg="white" paddingY={20} id={slug}>
      <Container maxW="container.xl" textAlign="center">
        <Heading size="3xl">{heading}</Heading>
        <Flex wrap="wrap" justifyContent="center">
          {CARDS.map((card, index) => (
            <Flex
              key={index}
              width={{ base: "100%", lg: "33%" }}
              direction="column"
              align="center"
            >
              <Image
                marginBottom={-6}
                pointerEvents="none"
                src={card.image}
                width="40ch"
                height="35ch"
                htmlWidth="300"
                htmlHeight="300"
                loading="lazy"
                alt=""
              />
              <Heading size="md" maxWidth="25ch" marginBottom={2}>
                <Trans>{card.heading}</Trans>
              </Heading>
              <Heading size="sm" variant="thin" maxWidth="30ch">
                <Trans>{card.subheading}</Trans>
              </Heading>
            </Flex>
          ))}
        </Flex>
      </Container>
    </Box>
  )
}

export default SectionAboutUs
