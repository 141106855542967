// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react"

import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"

const theme = extendTheme({
  fonts: {
    heading: "Poppins",
    body: "Poppins",
  },
  colors: {
    blue: {
      50: "#E5F1FF",
      100: "#B8D9FF",
      200: "#8AC0FF",
      300: "#5CA8FF",
      400: "#2E8FFF",
      500: "#0077FF",
      600: "#005FCC",
      700: "#004799",
      800: "#003066",
      900: "#001833",
    },
  },
  styles: {
    global: {
      body: {
        bg: "gray.50",
        color: "gray.800",
      },
      a: {
        color: "blue.500",
        _hover: {
          textDecoration: "none",
        },
      },
    },
  },
  components: {
    Link: {
      baseStyle: {
        position: "relative",
        whiteSpace: "nowrap",
        _before: {
          content: '""',
          position: "absolute",
          width: "100%",
          height: "3px",
          background: "blue.500",
          top: "100%",
          left: 0,
          pointerEvents: "none",
          transformOrigin: "100% 50%",
          transform: "scale3d(0, 1, 1)",
          transition: "transform 0.3s",
        },
        _hover: {
          textDecoration: "none",
          _before: {
            transformOrigin: "0% 50%",
            transform: " scale3d(1, 1, 1)",
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "full",
        _hover: {
          boxShadow: "md",
          transform: "translate3d(0,-2px,0)",
        },
        _active: {
          transform: "translate3d(0,2px,0)",
        },
      },
      sizes: {
        xl: { h: 18, minW: 56, fontSize: "lg", p: 8 },
      },
      defaultProps: {
        colorScheme: "blue",
        size: "xl",
      },
    },
    Heading: {
      variants: {
        bold: {
          fontWeight: "bold",
        },
        thin: {
          fontWeight: "normal",
        },
      },
      defaultProps: {
        variant: "bold",
      },
    },
    Card: {
      baseStyle: {
        display: "flex",
        flexDirection: "column",
        background: "white",
        alignItems: "center",
        gap: 8,
      },
      variants: {
        rounded: {
          padding: 8,
          borderRadius: "xl",
          boxShadow: "md",
        },
        smooth: {
          padding: 8,
          borderRadius: "base",
          boxShadow: "md",
        },
      },
      defaultProps: {
        variant: "rounded",
      },
    },
  },
})

console.log(theme)

export default theme
