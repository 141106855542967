import * as React from "react"
import {
  Box,
  Flex,
  Text,
  Button,
  IconButton,
  Stack,
  Collapse,
  Link,
  Container,
  Heading,
} from "@chakra-ui/react"
import {
  Trans,
  Link as I18Link,
  useTranslation,
} from "gatsby-plugin-react-i18next"
import slugify from "slugify"

const INTERNAL_LINKS = [
  "footer.links.privacy-policy",
  "footer.links.cookie-policy",
  "footer.links.terms-of-use",
]

const EXTERNAL_LINKS = [
  {
    label: "kontakt@360digitalnatives.pl",
    url: "mailto:kontakt@360digitalnatives.pl",
  },
  {
    label: "facebook.com/360digitalnatives",
    url: "https://facebook.com/360digitalnatives",
  },
  {
    label: "linkedin.com/company/360-digital-natives",
    url: "https://pl.linkedin.com/company/360-digital-natives",
  },
]

const FooterComponent = () => (
  <>
    <Box
      width="full"
      bg="gray.800"
      color="white"
      paddingY={16}
      maxWidth="100vw"
      overflow="hidden"
    >
      <Container maxW="container.xl">
        <Flex wrap="wrap">
          <Box flex={1}>
            <Box maxWidth="60ch">
              <Heading size="md" marginBottom={4}>
                <Trans>footer.heading</Trans>
              </Heading>
              <Text marginBottom={2}>
                <Trans>footer.text.1</Trans>
              </Text>
              <Text>
                <Trans>footer.text.2</Trans>
              </Text>
            </Box>
          </Box>
          <Box>
            <Heading size="md" marginBottom={4}>
              <Trans>footer.contact</Trans>
            </Heading>
            <Flex direction="column" gap={2}>
              {EXTERNAL_LINKS.map(({ label, url }, index) => (
                <Box key={index} maxWidth="100%" overflow="hidden">
                  <Link href={url}>{label}</Link>
                </Box>
              ))}
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
    <Box width="full" bg="gray.900" color="white" paddingY={6}>
      <Container maxW="container.xl">
        <Flex direction={{ base: "column", lg: "row" }}>
          <Box flex={1}>© 360digitalnatives 2016-2022</Box>
          <Flex
            flex={1}
            justifyContent="flex-end"
            wrap="wrap"
            gap={4}
            marginTop={{ base: 4, lg: 0 }}
            direction={{ base: "column", lg: "row" }}
          >
            {INTERNAL_LINKS.map((label, index) => (
              <NavItem key={index} label={label} />
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  </>
)

const NavItem = ({ label, ...props }) => {
  const { t } = useTranslation()
  const trans = t(label)
  const slug = slugify(trans, { lower: true })

  return (
    <Link as={I18Link} to={"/" + slug} whiteSpace="nowrap" {...props}>
      <Trans>{label}</Trans>
    </Link>
  )
}

export default FooterComponent
