import * as React from "react"
import {
  Box,
  Flex,
  Button,
  IconButton,
  Stack,
  Collapse,
  Link,
  Container,
  Heading,
  Image,
  useDisclosure,
} from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import {
  Link as I18Link,
  useI18next,
  useTranslation,
} from "gatsby-plugin-react-i18next"

import slugify from "slugify"
import logoImage from "../images/sygnet.svg"
import flagPL from "../images/icons/flag-pl.svg"
import flagEN from "../images/icons/flag-en.svg"

const NAV_ITEMS = [
  {
    label: "about.heading",
  },
  {
    label: "outsource.heading",
  },
  {
    label: "jobs.heading",
    href: "https://360digitalnatives.recruitify.ai/jobs",
  },
  {
    label: "join.heading",
    href: "https://360digitalnatives.recruitify.ai/cv",
  },
  {
    label: "contact.heading",
  },
]

const Logo = () => (
  <Flex as={I18Link} to={"/"} flex={1} height={"80%"} alignItems="center">
    <Image height="100%" src={logoImage} loading="lazy" alt="" />
    <Heading
      size="lg"
      variant="thin"
      whiteSpace="nowrap"
      color="black"
      _before={{
        borderLeftWidth: "1px",
        borderLeftColor: "gray.400",
        display: "inline",
        content: '""',
        marginX: ".5em",
      }}
    >
      Digital Natives
    </Heading>
  </Flex>
)

const Header = () => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const handleMenuItemClick = () => onClose()

  return (
    <Box
      position="fixed"
      width="full"
      top={0}
      left={0}
      bg="white"
      boxShadow="sm"
      height={isOpen ? "100vh" : "auto"}
      zIndex={"docked"}
    >
      <Container maxW="container.xl">
        <Flex
          as="header"
          height={{
            base: "3rem",
            lg: "4rem",
          }}
          alignItems="center"
          paddingY={1}
        >
          <Logo />
          <MovileNavToggleButton onClick={onToggle} />
          <DesktopNav />
        </Flex>
      </Container>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onMenuItemClick={handleMenuItemClick} />
      </Collapse>
    </Box>
  )
}

const Languages = ({ ...props }) => {
  const { language } = useI18next()

  return (
    <Button
      size="xs"
      as={I18Link}
      to={"/"}
      language={language === "en" ? "pl" : "en"}
      padding={0}
      overflow="hidden"
      colorScheme="gray"
      borderWidth="1px"
      borderColor="gray.200"
      width="4ch"
      height="3ch"
    >
      <Image
        borderRadius="md"
        height="100%"
        src={language === "en" ? flagPL : flagEN}
        alt={language === "en" ? "PL" : "EN"}
      />
    </Button>
  )
}

const NavItem = ({ href, label, ...props }) => {
  const { t } = useTranslation()
  const trans = t(label)
  const slug = slugify(trans, { lower: true })

  return (
    <Link
      href={href || undefined}
      to={!href ? "#" + slug : undefined}
      as={!href ? I18Link : undefined}
      {...props}
    >
      {trans}
    </Link>
  )
}

const DesktopNav = () => {
  return (
    <Stack
      display={{ base: "none", lg: "flex" }}
      flex={1}
      spacing={8}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      fontSize="lg"
    >
      {NAV_ITEMS.map((props, index) => (
        <NavItem key={index} whiteSpace="nowrap" {...props} />
      ))}
      <Languages />
    </Stack>
  )
}

const MovileNavToggleButton = ({ ...props }) => (
  <IconButton
    display={{ lg: "none" }}
    size="xs"
    flex={1}
    maxWidth="5ch"
    icon={<HamburgerIcon />}
    aria-label="Show menu"
    {...props}
  />
)

const MobileNav = ({ onMenuItemClick, ...props }) => {
  return (
    <Box display={{ lg: "none" }} p={8}>
      <Stack align="center">
        {NAV_ITEMS.map((props, index) => (
          <NavItem
            key={index}
            fontSize="lg"
            width="full"
            textAlign="center"
            onClick={onMenuItemClick}
            {...props}
          />
        ))}
        <Languages />
      </Stack>
    </Box>
  )
}

export default Header
