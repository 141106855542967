import * as React from "react"
import { Box, Flex, Button, Container, Heading, Image } from "@chakra-ui/react"
import { Trans, Link, useTranslation } from "gatsby-plugin-react-i18next"
import slugify from "slugify"
import Card from "../components/Card"

import img1 from "../images/outsource/1.png"
import img2 from "../images/outsource/2.png"
import img3 from "../images/outsource/3.png"
import img4 from "../images/outsource/4.png"
import img5 from "../images/outsource/5.png"
import img6 from "../images/outsource/6.png"
import img7 from "../images/outsource/7.png"

const CARDS = [
  {
    heading: "outsource.1.heading",
    subheading: "outsource.1.subheading",
    image: img1,
  },
  {
    heading: "outsource.2.heading",
    subheading: "outsource.2.subheading",
    image: img2,
  },
  {
    heading: "outsource.3.heading",
    subheading: "outsource.3.subheading",
    image: img3,
  },
  {
    heading: "outsource.4.heading",
    subheading: "outsource.4.subheading",
    image: img4,
  },
  {
    heading: "outsource.5.heading",
    subheading: "outsource.5.subheading",
    image: img5,
  },
  {
    heading: "outsource.6.heading",
    subheading: "outsource.6.subheading",
    image: img6,
  },
  {
    heading: "outsource.7.heading",
    subheading: "outsource.7.subheading",
    image: img7,
  },
]

const SectionOutsource = () => {
  const { t } = useTranslation()
  const heading = t("outsource.heading")
  const slug = slugify(heading, { lower: true })
  return (
    <Box as="section" paddingY={20} id={slug}>
      <Container maxW="container.xl" textAlign="center">
        <Heading size="3xl" marginBottom={2}>
          {heading}
        </Heading>
        <Heading size="md" variant="thin">
          <Trans>outsource.subheading</Trans>
        </Heading>

        <Button as={Link} to="/outsource" marginTop={4}>
          <Trans>outsource.button</Trans>
        </Button>

        <Flex wrap="wrap" justifyContent="center" gap={8} marginTop={16}>
          {CARDS.map((card, index) => (
            <Card direction="column" align="center" key={index} maxWidth="30ch">
              <Image
                pointerEvents="none"
                src={card.image}
                loading="lazy"
                htmlWidth="100"
                htmlHeight="100"
                alt=""
              />
              <Box>
                <Heading size="md" maxWidth="25ch" marginBottom={2}>
                  <Trans>{card.heading}</Trans>
                </Heading>
                <Heading size="sm" variant="thin" maxWidth="30ch">
                  <Trans>{card.subheading}</Trans>
                </Heading>
              </Box>
            </Card>
          ))}
        </Flex>
      </Container>
    </Box>
  )
}

export default SectionOutsource
