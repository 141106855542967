import React, { useState } from "react"
import { useToast } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import axios from "axios"

const ContactForm = props => {
  const toast = useToast()
  const { t: translate } = useTranslation()

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
    toast({
      title: translate(ok ? "form.success" : "form.error"),
      status: ok ? "success" : "error",
      duration: 5000,
      isClosable: true,
    })
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target

    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://formspree.io/f/mqknaoob",
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return <form onSubmit={handleOnSubmit}>{props.render(serverState)}</form>
}

export default ContactForm
