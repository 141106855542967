import * as React from "react"
import { Box, Flex, Button, Container, Heading, Image } from "@chakra-ui/react"
import { Trans, Link } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import heroImage from "../images/hero/image.svg"

const Hero = () => (
  <>
    <Box as="section" bg="white" paddingY={16} position="relative">
      <StaticImage
        placeholder="none"
        alt=""
        src="../images/hero/background.png"
        style={{
          display: "block",
          position: "absolute",
          left: 0,
          bottom: "15%",
          width: "100%",
          height: "70%",
          zIndex: 0,
        }}
        imgStyle={{
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      <Container maxW="container.xl" position="relative" zIndex={1}>
        <Flex direction={{ base: "column", lg: "row" }}>
          <Flex
            flex={1}
            alignItems="baseline"
            justifyContent="center"
            direction="column"
            gap={2}
            paddingY={{ base: 8, lg: 0 }}
          >
            <Heading size="3xl" as="h1">
              <Trans>hero.heading</Trans>
            </Heading>
            <Heading size="lg" variant="thin">
              <Trans>hero.subheading</Trans>
            </Heading>

            <Button
              as="a"
              href="https://360digitalnatives.recruitify.ai/cv"
              marginTop={8}
            >
              <Trans>hero.button</Trans>
            </Button>
          </Flex>

          <Flex flex={1} alignItems="center" justifyContent="center">
            <Image
              src={heroImage}
              width="100%"
              htmlWidth="300"
              htmlHeight="300"
              alt=""
            />
          </Flex>
        </Flex>
      </Container>
    </Box>
    <Container maxW="container.xl">
      <Flex gap={12} paddingY={12} justifyContent="center" wrap="wrap">
        <Card maxWidth="40ch">
          <StaticImage
            placeholder="none"
            alt=""
            src="../images/icons/road_left.png"
          />
          <Box textAlign="center" maxWidth="35ch">
            <Heading size="lg" marginBottom={2}>
              <Trans>hero.variants.1.heading</Trans>
            </Heading>
            <Heading as="h3" size="md" variant="thin">
              <Trans>hero.variants.1.subheading</Trans>
            </Heading>
          </Box>
          <Button as="a" href="https://360digitalnatives.recruitify.ai/jobs">
            <Trans>hero.variants.1.button</Trans>
          </Button>
        </Card>
        <Card maxWidth="40ch">
          <StaticImage
            placeholder="none"
            alt=""
            src="../images/icons/road_right.png"
          />
          <Box textAlign="center" maxWidth="35ch">
            <Heading size="lg" marginBottom={2}>
              <Trans>hero.variants.2.heading</Trans>
            </Heading>
            <Heading as="h3" size="md" variant="thin">
              <Trans>hero.variants.2.subheading</Trans>
            </Heading>
          </Box>
          <Button as={Link} to="/outsource" marginTop={4}>
            <Trans>hero.variants.2.button</Trans>
          </Button>
        </Card>
      </Flex>
    </Container>
  </>
)

export default Hero
