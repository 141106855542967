import * as React from "react"
import { Box, useStyleConfig } from "@chakra-ui/react"

const Card = ({ variant, children, ...props }) => {
  const styles = useStyleConfig("Card", { variant })

  return (
    <Box __css={styles} {...props}>
      {children}
    </Box>
  )
}

export default Card
