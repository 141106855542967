import * as React from "react"
import { graphql } from "gatsby"
import { Container, Heading } from "@chakra-ui/react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const IndexPage = () => (
  <Layout>
    <Seo title="404" />
    <Container marginTop={24} padding={48}>
      <Heading size="4xl" textAlign="center" width="full">
        404
      </Heading>
    </Container>
  </Layout>
)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
